import React from "react";
import PrivacyPolicy from "containers/visuella/PrivacyPolicy";

const PrivacyPolicyPage = () => {
  return (
    <>
      <PrivacyPolicy />
    </>
  );
};
export default PrivacyPolicyPage;
